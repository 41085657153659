import axios from 'axios';

let prefix = "/report"

const ReportFeedbackApi = {
	
	submitReport(payload, onProgress){
		let config = {
			onUploadProgress(progressEvent) {
				var percentCompleted = Math.round((progressEvent.loaded * 100) /progressEvent.total);
				// execute the callback
				if(percentCompleted == 100)
					setTimeout(function () {
						return true
					}, 1500);
					
				if(onProgress) 
					onProgress(percentCompleted)

				return percentCompleted;
			},
			headers: {
				'content-type': 'multipart/form-data' 
			}
		};

		return axios.post( prefix + "/submit", payload, config)
	},
}

export default ReportFeedbackApi;