import axios from 'axios';

let prefix = "/component"

const ComponentApi = {
	
	getComponentList(payload){
		return axios.get( prefix + "/get", {params: payload})
	},
}

export default ComponentApi;