import axios from 'axios';

let prefix = "/voucher"

const VoucherApi = {
	
	useVoucher(payload){
		return axios.post( prefix + "/use", payload)
	},
	calculateDiscountedPrice(payload){
		return axios.get( prefix + "/calculate-discount", {params: payload})
	},
}

export default VoucherApi;