import axios from 'axios';

let prefix = "/supportive"

const SupportiveApi = {
	
	getSupportive(type, payload = null){
		return axios.get( prefix + "/get/" + type, {params: payload})
	},
}

export default SupportiveApi;