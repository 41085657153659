const getDefaultState = () => {
	return {
		selectedSeat: {},

		selectedSeatModel: {},
	}
}

const state = getDefaultState();

const getters ={
	selectedSeat: (state) => state.selectedSeat,

	selectedSeatModel: (state) => state.selectedSeatModel,
};

const actions ={
	setSelectedSeat({commit}, selectedSeat){
		commit('setSelectedSeat', selectedSeat)
	},

	setSelectedSeatModel({commit}, selectedSeatModel){
		commit('setSelectedSeatModel', selectedSeatModel)
	},

	resetSelectedSeat({commit}){
		commit('resetSelectedSeat')
	}
};

const mutations ={
	setSelectedSeat: (state, selectedSeat) => {state.selectedSeat = selectedSeat},

	setSelectedSeatModel: (state, selectedSeatModel) => {state.selectedSeatModel = selectedSeatModel},

	resetSelectedSeat: (state) => {Object.assign(state, getDefaultState())},

};

export default {
	state,
	getters,
	actions,
	mutations,
}