let api_collection = {}

// auto register
let modules = require.context('./modules', false, /\.js$/)
modules.keys().map(path => {
	if (!path.includes('index.js')) {          
		let _module = modules(path).default || modules(path)
		api_collection =  Object.assign(api_collection, _module)
	}
})

const apiList = api_collection

export default apiList