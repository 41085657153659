import axios from 'axios';

let prefix = "/seatLogoType"

const SeatLogoTypeApi = {
	
	getSeatLogoTypeList(payload){
		return axios.get( prefix + "/get", {params: payload})
	},
}

export default SeatLogoTypeApi;