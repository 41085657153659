import axios from 'axios';

let prefix = "/auth"
let prefix_user = "/user"

const AuthApi = {
	
	changePassword(payload){
		return axios.put( prefix + "/edit-password", payload)
	},
	checkAuthPassword(){
		return axios.get( prefix + "/check-password")
	},
	updateAuthProfile(payload){
		return axios.put( prefix + "/update", payload)
	},
	forgotPassword(payload){
		return axios.post( prefix_user + "/forgotPassword", payload)
	},
	resetPassword(payload){
		return axios.post( prefix + "/reset-password", payload)
	}

}

export default AuthApi;