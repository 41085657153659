import axios from 'axios';

let prefix = "/seatLogo"

const SeatLogoApi = {
	
	getSeatLogoList(payload){
		return axios.get( prefix + "/get", {params: payload})
	},
}

export default SeatLogoApi;