import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		dark : false,
		themes: {
			light: {
				primary: '#FCDE0A',
				secondary: '#232323',
				'tertiary' : '#3EA6FF',
				'background': '#f0f2f5',
				"grey" : "#F0F0F0",
				'opposite': '#202020',
			},
			// dark: {
			// 	primary: '#08BAB4',
			// 	secondary: '#202020',
			// 	'tertiary' : '#3EA6FF',
			// 	'background': '#181818',
			// 	'opposite': '#FFFFFF',
			// },
		},
	}
});
