import bearerDriver from '@websanova/vue-auth/drivers/auth/bearer'
import axios from '@websanova/vue-auth/drivers/http/axios.1.x'
import router from '@websanova/vue-auth/drivers/router/vue-router.2.x'
import oauth2Google    from '@websanova/vue-auth/drivers/oauth2/google.js';
import oauth2Facebook  from '@websanova/vue-auth/drivers/oauth2/facebook.js';

oauth2Google.params.client_id = process.env.VUE_APP_GOOGLE_OAUTH_CLIENT_ID;
oauth2Google.params.scope = 'https://www.googleapis.com/auth/userinfo.profile email';
oauth2Facebook.params.client_id = process.env.VUE_APP_FACEBOOK_OAUTH_CLIENT_ID;
oauth2Facebook.url= 'https://www.facebook.com/v12.0/dialog/oauth',

// Auth base configuration some of this options
// can be override in method calls

/*
override bearer Driver
- take token from resposne.data instead of response.header
*/
bearerDriver.response = function (res) {
	return res.data?.token
}

const config = {
	auth: bearerDriver,
	http: axios,
	router: router,
	tokenDefaultKey : 'config-X',
	stores: ['storage'],
	rolesVar: 'role_id',
	registerData: {url: 'auth/register', method: 'POST', redirect: '/login'},
	loginData: {url: 'auth/login', method: 'POST', redirect: '', fetchUser: true},
	logoutData: {url: 'auth/logout', method: 'POST', redirect: '/', makeRequest: true},
	fetchData: {url: 'auth/user', method: 'GET', enabled: true},
	refreshData: {url: 'auth/refresh', method: 'GET', enabled: false, interval: 30},
	oauth2: {
		google: oauth2Google,
		facebook: oauth2Facebook,
	}
}

export default config