<template>
	<div class="fill-height">
		<v-btn small @click="test()">aaa</v-btn>
		<v-btn small @click="test2()">showWorldAxis</v-btn>
		<v-btn small @click="toggleInspector()">toggleInspector</v-btn>
		<v-btn small @click="loadLogo()">loadLogo</v-btn>
		<w-dropdown-menu
			left bottom
			:items="menu_items()"
		></w-dropdown-menu>
		<canvas id="renderCanvas" style="height: 550px; width: 100%;touch-action: none;"></canvas>
	</div>
</template>

<script>
import * as BABYLON from '@babylonjs/core/Legacy/legacy';
import "@babylonjs/loaders/glTF";
import "@babylonjs/core/Debug/debugLayer";
import "@babylonjs/inspector";
import WDropdownMenu from "@/components/WDropdownMenu"
export default {
	components:{
		WDropdownMenu
	},
	data(){
		return {
			engine: null,
			scene: null,
			canvas : null,
			camera: null,
			leather_mat : null,
			test_perforation : null,
			publicPath: process.env.BASE_URL,
			chair : null,
			chairList : [
				'AB0065.glb',
				'MD0438.glb',
				'MD0340.glb',
				// 'HD0198.glb',
			],
			selectedMesh: null,
			pointer: null,
			decal: null,
		}
	},
	mounted(){
		this.init()
		this.loadChair(this.chairList[0])
		console.log(process.env,"aa")
	},
	methods:{
		init(){
			const _this = this;
			this.canvas = document.getElementById('renderCanvas');

			// load the 3D engine
			this.engine = new BABYLON.Engine(this.canvas, true, { preserveDrawingBuffer: true, stencil: true });

			this.scene = this.createScene()
			this.scene.clearColor = BABYLON.Color3.FromInts(220,220,220);

			this.leather_mat = new BABYLON.Texture("./Leather Mask.png", this.scene);
			this.test_perforation = new BABYLON.Texture("./seamSelection.png", this.scene);

			// run the render loop
			this.engine.runRenderLoop(function(){
				_this.scene.render();
			});

			// the canvas/window resize event handler
			window.addEventListener('resize', function(){
				_this.engine.resize();
			});
		},
		createScene(){
			// create a basic BJS Scene object
			var scene = new BABYLON.Scene(this.engine);

			this.camera = new BABYLON.ArcRotateCamera("camera", Math.PI / 2, Math.PI / 2, 50, BABYLON.Vector3.Zero(), scene);
			// camera.upperRadiusLimit = 0.5;
			// camera.lowerRadiusLimit = 0.25;
			this.camera.minZ = 0.01;

			// target the camera to scene origin
			this.camera.setTarget(BABYLON.Vector3.Zero());

			// attach the camera to the canvas
			this.camera.attachControl(this.canvas, true);
			this.camera.lowerRadiusLimit = 5
			this.camera.upperRadiusLimit = 50

			// create a basic light, aiming 0,1,0 - meaning, to the sky
			// var light = new BABYLON.HemisphericLight('light1', new BABYLON.Vector3(0,1,0), scene);
			// light.intensity = 2;
			var light = new BABYLON.PointLight("Omni0", new BABYLON.Vector3(0, 10, 0), scene);			// var spotLight1 = new BABYLON.SpotLight('spotLight1', new BABYLON.Vector3(0, 30, -10), new BABYLON.Vector3(0, -1, 0), Math.PI / 3, 2, scene);
			// var spotLight2 = new BABYLON.SpotLight('spotLight2', new BABYLON.Vector3(0, 30, -10), new BABYLON.Vector3(0, -1, 0), Math.PI / 3, 2, scene);
			// var spotLight3 = new BABYLON.SpotLight('spotLight3', new BABYLON.Vector3(0, 30, -10), new BABYLON.Vector3(0, -1, 0), Math.PI / 3, 2, scene);

			this.mat1 = new BABYLON.StandardMaterial("mat1", scene);
			this.mat1.diffuseTexture = new BABYLON.Texture("http://i.imgur.com/Wk1cGEq.png", scene);
			this.mat1.diffuseColor = new BABYLON.Color3(1, 0, 0);
			this.mat1.bumpTexture = new BABYLON.Texture("http://i.imgur.com/wGyk6os.png", scene);
			
			// // create a built-in "ground" shape;
			// var ground = BABYLON.Mesh.CreateGround('ground1', 3, 3, 2, scene);
			var groundMat = new BABYLON.StandardMaterial("groundMat", scene);
			groundMat.diffuseColor = new BABYLON.Color3(0,0,1)
			groundMat.diffuseTexture = new BABYLON.Texture("http://i.imgur.com/Wk1cGEq.png", scene);
			console.log(light)

			// return the created scene
			return scene;
		},
		test(){
			const selectedMesh2 = this.selectedMesh.clone(this.selectedMesh.id)
			var mat2 = new BABYLON.StandardMaterial("dog2", this.scene);
			this.test_perforation.hasAlpha = true;
			mat2.diffuseTexture = this.test_perforation;
			mat2.diffuseTexture.uScale = 5;
			mat2.diffuseTexture.vScale = 5;
			mat2.useAlphaFromDiffuseTexture = true;
			selectedMesh2.material = mat2
			
			console.log(this.selectedMesh, selectedMesh2)
		},
		test2(){
			// let material = this.selectedMesh
			var mat = this.scene.getMeshByID(this.selectedMesh.id + '.' + this.selectedMesh.id);
			if(mat)
			{
				mat.dispose()
			}
			else
				console.log('nay')
		},
		loadShark(){			
			let shark = new BABYLON.Mesh();
			BABYLON.SceneLoader.ImportMesh("", "https://cdn.glitch.com/", "fbe5330c-72c9-4613-8c7d-21b7376284bd%2FShark.glb?v=1541686223911", this.scene, function (newMeshes) {    
				shark = newMeshes[0]
				// shark.position.x = 4
			});
			console.log(shark)
		},
		showWorldAxis(size = 20) {

			let scene = this.scene
			var makeTextPlane = function(text, color, size) {
				var dynamicTexture = new BABYLON.DynamicTexture("DynamicTexture", 50, scene, true);
				dynamicTexture.hasAlpha = true;
				dynamicTexture.drawText(text, 5, 40, "bold 36px Arial", color , "transparent", true);
				var plane = BABYLON.Mesh.CreatePlane("TextPlane", size, scene, true);
				plane.material = new BABYLON.StandardMaterial("TextPlaneMaterial", scene);
				plane.material.backFaceCulling = false;
				plane.material.specularColor = new BABYLON.Color3(0, 0, 0);
				plane.material.diffuseTexture = dynamicTexture;
				return plane;
			};

			var axisX = BABYLON.Mesh.CreateLines("axisX", [ 
				BABYLON.Vector3.Zero(), new BABYLON.Vector3(size, 0, 0), new BABYLON.Vector3(size * 0.95, 0.05 * size, 0), 
				new BABYLON.Vector3(size, 0, 0), new BABYLON.Vector3(size * 0.95, -0.05 * size, 0)
			], scene);
			axisX.color = new BABYLON.Color3(1, 0, 0);
			var xChar = makeTextPlane("X", "red", size / 10);
			xChar.position = new BABYLON.Vector3(0.9 * size, -0.05 * size, 0);

			var axisY = BABYLON.Mesh.CreateLines("axisY", [
				BABYLON.Vector3.Zero(), new BABYLON.Vector3(0, size, 0), new BABYLON.Vector3( -0.05 * size, size * 0.95, 0), 
				new BABYLON.Vector3(0, size, 0), new BABYLON.Vector3( 0.05 * size, size * 0.95, 0)
			], scene);
			axisY.color = new BABYLON.Color3(0, 1, 0);
			var yChar = makeTextPlane("Y", "green", size / 10);
			yChar.position = new BABYLON.Vector3(0, 0.9 * size, -0.05 * size);
			var axisZ = BABYLON.Mesh.CreateLines("axisZ", [
				BABYLON.Vector3.Zero(), new BABYLON.Vector3(0, 0, size), new BABYLON.Vector3( 0 , -0.05 * size, size * 0.95),
				new BABYLON.Vector3(0, 0, size), new BABYLON.Vector3( 0, 0.05 * size, size * 0.95)
			], scene);
			axisZ.color = new BABYLON.Color3(0, 0, 1);
			var zChar = makeTextPlane("Z", "blue", size / 10);
			zChar.position = new BABYLON.Vector3(0, 0.05 * size, 0.9 * size);
		},
		loadLogo(){
			let theChair = this.chair.getChildren()[13]
			let headPart = theChair.getChildren()[5]
			console.log(theChair, "theChair")

			// let initPoint = this.scene.getNodeByID('SOCKET_Backrest03')
			let initPoint = this.scene.getNodeByID('SOCKET_Headrest01')

			var materialPlane = new BABYLON.StandardMaterial("texturePlane", this.scene);
			materialPlane.diffuseTexture = new BABYLON.Texture("./huawei.png", this.scene);
			materialPlane.diffuseTexture.hasAlpha = true;
			materialPlane.opacityTexture = materialPlane.diffuseTexture;
			materialPlane.backFaceCulling = false
			materialPlane.zOffset = -2;
	
			const _this = this;

			var onPointerDown = function (evt) {

				console.log("onPointerDown")
				if (evt.button !== 0) {
					return;
				}

				// check if we are under a mesh
				var pickInfo = _this.scene.pick(_this.scene.pointerX, _this.scene.pointerY, function (mesh) { 
					console.log(mesh.name, _this.scene.pointerX)
					return mesh === headPart; 
				});
				console.log(pickInfo,"pickInfo")


				if (pickInfo.hit) {
					var decalSize = new BABYLON.Vector3(1, 1, 1);

					_this.decal && _this.decal.dispose()

					console.log("hit")
					/**************************CREATE DECAL*************************************************/
					var decal = BABYLON.MeshBuilder.CreateDecal("logo_decal", headPart, {
						position: pickInfo.pickedPoint,
						normal: pickInfo.getNormal(true),
						size: decalSize
					});
					decal.material = materialPlane;
					_this.decal = decal
					/***************************************************************************************/
				}
			}
			_this.canvas.addEventListener("pointerdown", onPointerDown, false);

			_this.scene.onDispose = function () {
				_this.canvas.removeEventListener("pointerdown", onPointerDown);
			}

			// this.pointer.position = position
		},
		loadChair(obj_name){
			if(this.chair){
				console.log(this.chair.getChildMeshes())
				this.chair.getChildMeshes().forEach((item)=>{
					item.material && item.material.dispose()
				})
				this.chair.dispose()
				// this.scene.removeMesh(this.chair);
			}

			let pointer = BABYLON.MeshBuilder.CreateSphere('red_pointer', {diameterX: 1, diameterY: 1, diameterZ: 1});
			pointer.material = new BABYLON.StandardMaterial("TextPlaneMaterial", this.scene);
			pointer.material.backFaceCulling = false;
			pointer.material.diffuseColor = new BABYLON.Color3(1, 0, 0);
			this.pointer = pointer

			BABYLON.SceneLoader.ImportMeshAsync("", "./", obj_name, this.scene).then((res)=>{
				console.log(res)
				this.chair = res.meshes[0]
				this.chair.position.x = 1
				this.chair.position.y = -10

				this.chair.getChildMeshes().forEach((item)=>{
					item.material.albedoColor = new BABYLON.Color3.FromInts(108,108,100);
					item.material.albedoTexture = this.leather_mat
					item.material.albedoTexture.uScale = 18;
					item.material.albedoTexture.vScale = 18;
				})
				
				let hightlight = new BABYLON.HighlightLayer("hightlight", this.scene);
				let sockets = this.chair.getChildren().filter((item)=> item.id.startsWith('SOCKET'))
				sockets.forEach((item)=>{
					let sphere = BABYLON.MeshBuilder.CreateSphere('btn_'+item.id, {diameterX: 0.2, diameterY: 0.5, diameterZ: 0.5});
					sphere.parent = this.chair
					sphere.position = item.position.clone()
					sphere.rotationQuaternion = item.rotationQuaternion

					// let zoom_point = new BABYLON.TransformNode('zoom_point_'+item.id);
					let zoom_point = BABYLON.MeshBuilder.CreateSphere('zoom_point_'+item.id,{diameterX: 0.1, diameterY: 0.1, diameterZ: 0.1});
					zoom_point.parent = this.chair
					zoom_point.isVisible = false
					zoom_point.position = item.position.clone()
					zoom_point.rotationQuaternion = item.rotationQuaternion
					zoom_point.translate(BABYLON.Axis.X, -10, BABYLON.Space.LOCAL);

					hightlight.addMesh(sphere, new BABYLON.Color3.FromInts(30,70,90) )
					// hightlight.innerGlow = false;
					sphere.actionManager = new BABYLON.ActionManager(this.scene);
					let _this = this
					sphere.actionManager.registerAction(
						new BABYLON.ExecuteCodeAction({
							trigger: BABYLON.ActionManager.OnPickTrigger,
						},
						function (e) {
							console.log(e);
							_this.zoomToSocket(e,zoom_point)
						})
					)

					let relatedPart = this.linkPartSocket(zoom_point)
					relatedPart.socket = sphere
					sphere.relatedPart = relatedPart
				})
			})
		},
		linkPartSocket(zoomPoint, showRay = false){

			var origin = zoomPoint.absolutePosition;

			var forward = new BABYLON.Vector3(1,0,0);		
			forward = this.vecToLocal(forward, zoomPoint);

			var direction = forward.subtract(origin);
			direction = BABYLON.Vector3.Normalize(direction);

			var ray = new BABYLON.Ray(origin, direction, 25);
			if(showRay){
				let rayHelper = new BABYLON.RayHelper(ray);		
				rayHelper.show(this.scene);	
			}	

			let relatedPart = this.scene.pickWithRay(ray, function(mesh){
				return mesh != zoomPoint
			})

			return relatedPart.pickedMesh
		},
		zoomToSocket(e, zoom_point){
			console.log('event',e)
			let mesh = e.meshUnderPointer
			console.log(mesh,zoom_point, e)
			var speed = 100;
			var frameCount = 25;
			this.camera.target = mesh
			let to = zoom_point.absolutePosition
			this.camera.position = to
			this.selectedMesh = mesh.relatedPart

			console.log('selectedMesh', this.selectedMesh)

			// to = mesh.position.normalizeFromLength(10)
			// let zz = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
			// console.log(zz)
			//this.animateCameraToPosition(this.camera,speed,frameCount, to)

			// var origin = mesh.absolutePosition;

			// var forward = new BABYLON.Vector3(1,0,0);		
			// forward = this.vecToLocal(forward, mesh);

			// var direction = forward.subtract(origin);
			// direction = BABYLON.Vector3.Normalize(direction);

			// var ray = new BABYLON.Ray(origin, direction, 100);

			// let aa = this.scene.pickWithRay(ray, function(a){
			// 	return a != mesh
			// })
			// console.log(aa.pickedMesh.id)

			// let rayHelper = new BABYLON.RayHelper(ray);		
			// rayHelper.show(this.scene);		

			// this.pointer.position = origin

			// this.camera.rotationQuaternion = mesh.rotationQuaternion
		},
		vecToLocal(vector, mesh){
			var m = mesh.getWorldMatrix();
			var v = BABYLON.Vector3.TransformCoordinates(vector, m);
			return v;		 
		},
		animateCameraTargetToPosition(cam, speed, frameCount, newPos) {
			var ease = new BABYLON.SineEase();
			ease.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEINOUT);

			var aable1 = BABYLON.Animation.CreateAndStartAnimation('at5', cam, 'target', speed, frameCount, cam.target, newPos, 0, ease, this.targAnimEnded);
			aable1.disposeOnEnd = true;
		},
		animateCameraToPosition(cam, speed, frameCount, newPos) {
			var ease = new BABYLON.SineEase();
			ease.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEINOUT);
			var aable2 = BABYLON.Animation.CreateAndStartAnimation('at4', cam, 'position', speed, frameCount, cam.position, newPos, 0, ease, this.camPosAnimEnded);
			aable2.disposeOnEnd = true;
		},
		targAnimEnded(){
			console.log("targAnimEnded")
		},
		camPosAnimEnded(){
			console.log("camPosAnimEnded")
		},
		toggleInspector(){
			let inspector = this.scene.debugLayer
			if(inspector.isVisible() ){
				inspector.hide()
			}else{
				inspector.show({
					// embedMode: true,
				});
			}
		},
		menu_items(){
			let aa = this.chairList.map((item)=>{
				return {
					icon: 'mdi-sofa-single', title: item,
					action: ()=>{ this.loadChair(item) }
				}
			})

			return [ 
				... aa,
				{
					icon: 'mdi-shark-fin', title: "Nemo",
					action: ()=>{ this.loadShark() }
				}
			]
		},
	}
}
</script>
