import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import api from './api/index.js'
import './bootstrap'
import dayjs from 'dayjs'
import VueRouter from 'vue-router'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import auth from './auth'
import VueAuth from '@websanova/vue-auth'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import '@mdi/font/css/materialdesignicons.min.css';
import '@/styles/bw_lib.scss'
import store from './store';

//auth user
import * as AuthUser from "@/helpers/AuthUser.js"
Vue.prototype.$authUser = AuthUser

window.Vue = Vue // Set Vue globally

Vue.router = router

Vue.use(VueRouter)
Vue.use(VueAxios, axios)
Vue.use(VueAuth, auth)

const debugMode = process.env.NODE_ENV !== 'production'
var baseurl = window.location.protocol + "//" + window.location.host;
if(debugMode){
	axios.defaults.baseURL = baseurl+`/api`
}else{
	axios.defaults.baseURL = process.env.VUE_APP_BASE_API_URL + "/api/"
}

Vue.prototype.$api = api
Vue.prototype.$dayjs = dayjs

Vue.config.productionTip = false

new Vue({
	store,
	vuetify,
	router,
	render: h => h(App)
}).$mount('#app')

//Apple Sign IN
import VueAppleLogin from 'vue-apple-login';

Vue.use(VueAppleLogin, {
	clientId: process.env.VUE_APP_APPLE_OAUTH_CLIENT_ID,
	scope: 'name email',
	redirectURI: process.env.VUE_APP_BASE_URL + '/login',
	state: 'STATE',
	usePopup: true,
});

//VUETIFY DIALOG
import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css'
Vue.use(VuetifyDialog, {
	context: {
		vuetify
	},
	confirm: {
		icon: "mdi-alert",
		actions: {
			false: 'No',
			true: {
				text: 'Yes',
				color: 'error'
			}
		}
	}
})

//websocket testing
// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
// 	broadcaster: 'pusher',
// 	key: 'zlock123',
// 	//cluster: process.env.MIX_PUSHER_APP_CLUSTER,
// 	encrypted: false,
// 	wsHost: 'app.zlock.com.my',
// 	wsPort: 6001,
// 	wssPort: 6001,
// });

// vuetify snackbar
Vue.use(VueToast, {
	duration: 5000,
	dismissible : true,
});