import axios from 'axios';

let prefix = "/promotion"

const PromotionApi = {
	
	getPromotionList(payload){
		return axios.get( prefix + "/get", {params: payload})
	},
}

export default PromotionApi;