import axios from 'axios';

let prefix = "/regionalOffice"

const RegionalOfficeApi = {
	
	getRegionalOfficeList(payload){
		return axios.get( prefix + "/get", {params: payload})
	},
}

export default RegionalOfficeApi;