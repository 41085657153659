import axios from 'axios';

let prefix = "/paymentRecord"

const AuthApi = {
	
	getPaymentType(){
		return axios.get( prefix + "/getPaymentType")
	},
	getPaymentRecord(payload){
		return axios.get( prefix + "/get", {params: payload})
	},
	getPaymentGateway(payload){
		return axios.post( prefix + "/purchaseOrder", payload)
	},
}

export default AuthApi;