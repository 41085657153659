<template>
	<div id="app">
		<v-app>
			<v-main v-if="auth_ready" class="background">
				<transition name="fade">
					<router-view></router-view>
				</transition>
			</v-main>
			<v-main v-if="!auth_ready">
				<v-row justify="center" class="text-center fill-height">
					<v-col cols="12" align-self="end">
						<v-progress-circular
							:size="70"
							:width="7"
							color="primary"
							indeterminate
						></v-progress-circular>
					</v-col>
					<v-col class="font-weight-bold">Loading ...</v-col>
				</v-row>
			</v-main>
		</v-app>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

export default {
	data(){
		return {
			auth_ready : true,
		}
	},
	computed: {
		...mapGetters(["redirectTimer", "redirectAfterLogin"]),
	},
	mounted() {
		this.setTheme();
		let redirectTimer = new Date(this.redirectTimer)
		if(redirectTimer)
		{
			if(new Date(Date.now()) > redirectTimer)
			{
				localStorage.removeItem("orderCustomizations")
				localStorage.removeItem("orderOptions")
				localStorage.removeItem("isCustomizating")
				this.setRedirect(false)
				let i = 1;

				while(i<=100)
				{
					let part_spec = JSON.parse(localStorage.getItem("partSpecs_"+i))
					if(!part_spec)
						break;

					localStorage.removeItem("partSpecs_"+i)
					i++;
				}
			}
		}

	},
	methods:{
		...mapActions(["setRedirect"]),
		setTheme(){
			let isDarkTheme = JSON.parse(localStorage.getItem("dark-theme"))
			this.$vuetify.theme.dark = isDarkTheme || false;
		},
	}
}
</script>

<style scoped>
.theme--dark.v-application {
	background: #303030;
}
.theme--light.v-application {
	background: #F5F5F5;
}
</style>