import config from '@/configs'
import isFunction from 'lodash/isFunction'

const appTitle = config.appTitle


/**
 * Help to set browser's page title.
 *
 * @param {(string|function(appTitle))} pageName
 * @return null
 */
export function setPageTitle(pageName = null){
	let title = ""
	if(isFunction(pageName)){
		title = pageName(appTitle)
	}else{
		title = (pageName != undefined) ? (pageName + ' – ' + appTitle) : appTitle;
	}

	document.title = title
}