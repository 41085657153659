<template>
	<v-container fluid fill-height class="pa-0">
		<v-row no-gutters class="fill-height">
			<!-- <v-col></v-col> -->
			<v-col>
				<test></test>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import test from '@/views/test'
export default {
	name: 'Home',
	components: {
		test
	}
}
</script>
