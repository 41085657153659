import axios from 'axios';

let prefix = "/color"

const ColorApi = {
	
	getColorList(payload){
		return axios.get( prefix + "/get", {params: payload})
	},
}

export default ColorApi;