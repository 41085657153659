import axios from 'axios';

let prefix = "/maker"

const MakerApi = {
	
	getMakerList(payload){
		return axios.get( prefix + "/get", {params: payload})
	},
}

export default MakerApi;