import axios from 'axios';

let prefix = "/order"
let prefixPayment = "/paymentMethod"
let prefixShipping = "/shippingMethod"
let prefixCover = "/coverPrice"
let prefixCourier = "/courier"
let prefixDelivery = "/delivery"
let prefixGeoZone = "/geo-zone"
const OrderApi = {
	
	getOrderList(payload){
		return axios.get( prefix + "/get", {params: payload})
	},

	createOrder(payload){
		return axios.post( prefix + "/create", payload)
	},

	updateOrder(payload){
		return axios.post( prefix + "/update", payload)
	},
	uploadScreenshot(payload,onProgress){
		let config = {
			onUploadProgress(progressEvent) {
				var percentCompleted = Math.round((progressEvent.loaded * 100) /progressEvent.total);
		
				// execute the callback
				if(onProgress) 
					onProgress(percentCompleted)

				return percentCompleted;
			},
			headers: {
				'content-type': 'multipart/form-data' 
			}
		};

		return axios.post( prefix + "/preview/upload", payload, config)
	},
	uploadPartSpecScreenshot(payload, onProgress, num){
		let config = {
			onUploadProgress(progressEvent) {
				var percentCompleted = Math.round((progressEvent.loaded * 100) /progressEvent.total);
		
				// execute the callback
				if(onProgress) 
					onProgress(percentCompleted)

				return percentCompleted;
			},
			headers: {
				'content-type': 'multipart/form-data' 
			}
		};

		return axios.post( prefix + "/preview/partSpecUpload", payload, config)
	},
	sendEmail(payload){
		return axios.post( prefix + "/email/sendOrder", payload)
	},
	finalizeOrder(payload){
		return axios.post( prefix + "/finalize", payload)
	},

	getETD(payload){
		return axios.post( prefix + "/etd/get", {params: payload})
	},

	getPaymentMethod(payload){
		return axios.get( prefixPayment + "/get", {params: payload})
	},

	getShippingMethod(payload){
		return axios.get( prefixShipping + "/get", {params: payload})
	},

	getCourierList(payload){
		return axios.get( prefixCourier + "/get", {params: payload})
	},

	getDeliveryPartnerList(payload){
		return axios.get( prefixDelivery + "/getDeliveryPartners", {params: payload})
	},

	getOrderPrice(payload){
		return axios.post( prefix + "/calculatePrice", payload)
	},

	getCoverPrice(payload){
		return axios.get( prefixCover + "/getBySd", {params: payload})
	},

	getCountryList(payload){
		return axios.get( prefixGeoZone + "/country/get", {params: payload})
	},

}

export default OrderApi;