import Vue from 'vue'
import VueRouter from 'vue-router'
import { setPageTitle } from '@/helpers/pageTitle'

// Layout
const BaseLayout = () => import('@/views/_layouts/BaseLayout')

// Common Pages
const Comingsoon = () => import( '@/views/_general/Comingsoon')
const Error404 = () => import( '@/views/_general/404')
const ForgotPassword = () => import('@/views/_general/ForgotPassword')
const Login = () => import( '@/views/_general/Login')
const LoginConsumer = () => import( '@/views/_general/LoginConsumer')
const LoginOauth = () => import( '@/views/_general/LoginOauth')
const SignUpConsumer = () => import( '@/views/_general/SignUpConsumer')
const MobileLogin = () => import( '@/views/_general/MobileLogin')
const Home = () => import( '@/views/_general/Home')
const ResetPassword = () => import('@/views/_general/ResetPassword')

const OrderHistoryList = () => import('@/views/OrderHistories/OrderHistoryList')
const OrderHistoryInfo = () => import('@/views/OrderHistories/OrderHistoryInfo')
const OrderConfirmation = () => import('@/views/Customizations/OrderConfirmation')
const OrderFailed = () => import('@/views/Customizations/OrderFailed')
const CustomizationIndex = () => import('@/views/Customizations/index')
const SelectSeatPage = () => import('@/views/Customizations/SelectSeat')
const CustomizationPage = () => import('@/views/Customizations/CustomizationPage')
const OrderOptionsPage = () => import('@/views/Customizations/OrderOptionsPage')
const View2dPage = () => import('@/views/Customizations/View2dPage')
const OrderSummaryPage = () => import('@/views/Customizations/OrderSummaryPage')
const PaymentDeliveryPage = () => import('@/views/Customizations/PaymentDeliveryPage')

const Promotions = () => import('@/views/Promotions/Promotions')
const ContactUs = () => import('@/views/ContactUs/ContactUs')
const ReportForm = () => import('@/views/ReportForm/ReportForm')

const Supportives = () => import('@/views/Supportives/SupportiveSingle')
import Home2 from '../views/Home.vue'

// Routes
// ** for more information, kindy refer to https://github.com/websanova/vue-auth/blob/master/docs/Privileges.md
// ** auth: true <- can be access only if auth.check = true
// ** auth: false <- cannot be access is auth.check = true
// ** auth: null, title: '' <- no auth rule
// ** auth: [1,2] <- can be access is auth.user.role matched

const baseLayoutRoutes = [
	{ path: '/comingsoon', name: 'admin.home2', component: Comingsoon, meta: { auth: [ 1,5 ], title: 'Coming Soon' } },
	{ path: '/home', name: 'home', component: Home, meta: { auth: true, title: 'Home' } },
	{ path: '/test', name: 'home2', component: Home2, meta: { auth: true, title: 'Home' } },
	{ path: '/order-histories', name: 'OrderHistoryList', component: OrderHistoryList, meta: { auth: true, title: 'Order Histories' } },
	{ path: '/order-histories/info', name: 'OrderHistoryInfo', component: OrderHistoryInfo, meta: { auth: true, title: 'Order Histories' } },
	{ path: '/new-order', name: 'CustomizationIndex', component: CustomizationIndex, meta: { auth: true, title: 'New Order' } },
	{ path: '/promotions', name: 'PromotionList', component: Promotions, meta: { auth: true, title: 'Promotions' } },
	{ path: '/contact-us', name: 'ContactUs', component: ContactUs, meta: { auth: true, title: 'Contact Us' } },
	{ path: '/report', name: 'ReportForm', component: ReportForm, meta: { auth: null, title: 'Report Form' } },
	{ path: '/policy', name: 'Supportives', component: Supportives, meta: { auth: true, title: 'Policies' } },
	
	// { 
	// 	path: '/new-order/customization', name: 'CustomizationPage', component: CustomizationPage, 
	// 	meta: { 
	// 		auth: true, title: 'New Order', 
	// 		cartBtn :true ,
	// 		attrs: { style:"background: #DADADA;", class: "pa-0" }
	// 	}
	// },
	{ 
		path: '/new-order/select-seat', name: 'SelectSeatPage', component: SelectSeatPage, 
		meta: { 
			auth: true, 
			title: 'Select Seat Model' 
		} 
	},
	{ 
		path: '/new-order/customization', name: 'CustomizationPage', component: CustomizationPage, 
		meta: { 
			keepAlive: true,
			auth: true, title: 'New Order', 
			cartBtn :true ,
			attrs: { style:"background: #FFFFFF height: 100%", class: "pa-0" }
		}
	},
	{ 
		path: '/new-order/order-options', name: 'OrderOptionsPage', component: OrderOptionsPage, 
		meta: { 
			keepAlive: true,
			auth: true, title: 'Order Options', 
			cartBtn :true ,
			attrs: { style:"background: #FFFFFF", class: "pa-0" }
		},		
	},
	{
		path: '/new-order/view-2d', name: 'View2dPage', component: View2dPage, 
		meta: { 
			keepAlive: true,
			auth: true, title: 'View 2D', 
			cartBtn :true ,
			attrs: { style:"background: #FFFFFF", class: "pa-0" }
		}
	},
	{
		path: '/new-order/order-summary', name: 'OrderSummaryPage', component: OrderSummaryPage, 
		meta: { 
			keepAlive: true,
			auth: true, title: 'Order Summary', 
			cartBtn :true ,
			attrs: { style:"background: #FFFFFF", class: "pa-0" }
		}
	},
	{
		path: '/new-order/payment-delivery', name: 'PaymentDeliveryPage', component: PaymentDeliveryPage, 
		meta: { 
			keepAlive: true,
			auth: true, title: 'Payment & Delivery', 
			cartBtn :true ,
			attrs: { style:"background: #FFFFFF", class: "pa-0" }
		}
	},

	
	{ 
		path: '/new-order/order-confirmed', name: 'OrderConfirmation', component: OrderConfirmation, 
		meta: { 
			auth: true, 
			title: 'Order Confirmed', 
			attrs: { style:"background: #FFFFFF;" } 
		} 
	},
	{ 
		path: '/new-order/order-failed', name: 'OrderFailed', component: OrderFailed, 
		meta: { 
			auth: true, 
			title: 'Order Failed', 
			attrs: { style:"background: #FFFFFF;" } 
		} 
	},
]

const routes = [
	{ path: '/', name: 'LoginA', component: Login, meta: { auth: null, title: 'Login' } },
	{ path: '/login', name: 'loginB', component: Login, meta: { auth: null, title: 'Login' } },
	{ path: '/login-consumer', name: 'loginConsumer', component: LoginConsumer, meta: { auth: null, title: 'Consumer Login' } },
	{ path: '/login/:type', name: 'LoginOauth', component: LoginOauth, meta: { auth: null, title: 'Login' } },
	{ path: '/sign-up', name: 'signUpConsumer', component: SignUpConsumer, meta: { auth: null, title: 'Sign Up' } },
	{ path: '/mobile-login', name: 'mobileLogin', component: MobileLogin, meta: { auth: null, title: 'Mobile Login' } },
	{ path: '/forgot-password', name: 'forgotPassword', component: ForgotPassword, meta: { auth: null, title: 'Login' } },
	{
		path: '/about',
		name: 'About',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
	},

	// base_layout
	{ path: '/home', name: 'base_layout', component: BaseLayout, children: baseLayoutRoutes },

	{ path: '*', name: '404', component: Error404, meta: { auth: null, title: '404' } },
]

const router = new VueRouter({
	history: true,
	mode: 'history',
	routes
})

//Check if back button is clicked
window.popStateDetected = false
window.addEventListener('popstate', () => {
	window.popStateDetected = true
})

router.beforeEach((to, from, next) => {

	setPageTitle(to.meta.title)
	next()
});

export default router
