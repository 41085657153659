import Vuex from 'vuex';
import Vue from 'vue';
import orderCustomizations from './modules/orderCustomizations';
import selectedSeat from './modules/selectedSeat';

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
	modules:{
		orderCustomizations,
		selectedSeat
	},
	plugins: [createPersistedState({
		paths: ["selectedSeat"]
	})],

})