import axios from 'axios';

let prefix = "/sd"

const SDApi = {
	
	getSDList(payload){
		return axios.get( prefix + "/get", {params: payload})
	},
	getSDModelList(payload){
		return axios.get( prefix + "/model/get", {params: payload})
	},
	getSDYearList(payload){
		return axios.get( prefix + "/year/get", {params: payload})
	},
}

export default SDApi;