import axios from 'axios';

let prefix = "/user"
let prefixAddress = "/address"

const UserApi = {
	
	getUserInfo(id, payload){
		return axios.get( prefix + "/info/" + id, {params: payload})
	},
	getUserList(payload){
		return axios.get( prefix + "/get", {params: payload})
	},
	createUser(payload){
		return axios.post( prefix + "/create", payload)
	},
	updateUser(payload){
		return axios.put( prefix + "/update", payload)
	},
	archiveUser(payload){
		return axios.delete( prefix + "/archive", {params: payload})
	},
	getUserRefs(payload){
		return axios.get( prefix + "/refs", {params: payload})
	},
	updateUserPassword(payload){
		return axios.put( prefix + "/update-password", payload)
	},
	checkUserHasPassword(payload){
		return axios.get( prefix + "/check-password", {params: payload})
	},
	helpResetPassword(payload){
		return axios.post( prefix + "/forgot-password", payload)
	},
	getUserAddress(payload){
		return axios.get( prefixAddress + "/get", {params: payload})
	},
	createUserAddress(payload){
		return axios.post( prefixAddress + "/create", payload)
	},

}

export default UserApi;