import axios from 'axios';

let prefix = "/perforation"

const PerforationApi = {
	
	getPerforationList(payload){
		return axios.get( prefix + "/get", {params: payload})
	},
}

export default PerforationApi;