import Vue from 'vue'
import userConst from '@/configs/userConst'

function checkRole_(roles){
	if(!(roles instanceof Array)){
		roles = [ roles ]
	}
	let authUser = Vue.prototype.$auth.user()
	
	if(authUser)
		return [...roles].includes(authUser.role_id)
	else 
		return false
}

/*
### CUSTOMER FUNCTION HERE ###
*/
function isCustomer(){
	return checkRole_([
		userConst.ROLE_CONSUMER,
		userConst.ROLE_GUEST,
	]);
}

function isShowroom(){
	return checkRole_([
		userConst.ROLE_SHOWROOM,
	]);
}

function isGuest()
{
	return checkRole_([
		userConst.ROLE_GUEST,
	]);
}

function isBdu()
{
	return checkRole_([
		userConst.ROLE_BDU,
	]);
}

export{
	isCustomer,
	isGuest,
	isBdu,
	isShowroom
}