import axios from 'axios';

let prefix = "/designUpgrade"

const DesignUpgradeApi = {
	
	getDesignUpgradeList(payload){
		return axios.get( prefix + "/get", {params: payload})
	},
}

export default DesignUpgradeApi;