import axios from 'axios';

let prefix = "/seam"

const SeamApi = {
	
	getSeamList(payload){
		return axios.get( prefix + "/get", {params: payload})
	},
}

export default SeamApi;