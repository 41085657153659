import axios from 'axios';

let prefix = "/specialMaterial"

const SpecialMaterialApi = {
	
	getSpecialMaterialList(payload){
		return axios.get( prefix + "/get", {params: payload})
	},
}

export default SpecialMaterialApi;