const getDefaultState = () => {
	return {
		orderCustomizations: {},
		partSpecs: [],
		orderOptions: {},
		isCustomization: true,
		redirectTimer: null,
		redirectAfterLogin: false,
		priceInfo: {},
		selectionCounter: {},
	}
}

const state = getDefaultState();

const getters ={
	orderCustomizations: (state) => state.orderCustomizations,

	partSpecs: (state) => state.partSpecs,

	orderOptions: (state) => state.orderOptions,

	isCustomization: (state) => state.isCustomization,

	priceInfo: (state) => state.priceInfo,

	selectionCounter: (state) => state.selectionCounter,

	redirectTimer: (state) => state.redirectTimer,

	redirectAfterLogin: (state) => state.redirectAfterLogin,
};

const actions ={
	setOrderCustomizations({commit}, orderCustomizations){
		commit('setOrderCustomizations', orderCustomizations)
	},

	setOrderOptions({commit}, orderOptions){
		commit('setOrderOptions', orderOptions)
	},

	setPartSpecs({commit}, partSpecs){
		commit('setPartSpecs', partSpecs)
	},

	setIsCustomization({commit}, isCustomization){
		commit('setIsCustomiztion', isCustomization)
	},

	setPriceInfo({commit}, priceInfo){
		commit('setPriceInfo', priceInfo)
	},

	setSelectionCounter({commit}, selectionCounter){
		commit('setSelectionCounter', selectionCounter)
	},

	resetOrderCustomizations({commit}){
		commit('resetOrderCustomizations')
	},

	setRedirect({commit}, redirectAfterLogin){
		commit('setRedirect', redirectAfterLogin)
	},
};

const mutations ={
	setOrderCustomizations: (state, orderCustomizations) => {state.orderCustomizations = orderCustomizations},

	setOrderOptions: (state, orderOptions) => {state.orderOptions = orderOptions},

	setPartSpecs: (state, partSpecs) => {state.partSpecs = partSpecs},

	setIsCustomiztion: (state, isCustomization) => {state.isCustomization = isCustomization},

	setPriceInfo: (state, priceInfo) => {state.priceInfo = priceInfo},

	setSelectionCounter: (state, selectionCounter) => {state.selectionCounter = selectionCounter},

	resetOrderCustomizations: (state) => {Object.assign(state, getDefaultState())},
	
	setRedirect: (state, redirectAfterLogin) => {
		state.redirectTimer = new Date(Date.now() + (5 * 60 * 1000))
		state.redirectAfterLogin = redirectAfterLogin
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
}