import axios from 'axios';

let prefix = "/driverSeat"

const DriverSeatApi = {
	
	getDriverSeatList(payload){
		return axios.get( prefix + "/get", {params: payload})
	},
}

export default DriverSeatApi;